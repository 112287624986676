import {
  db,
  FirebaseCallable,
  functions,
  httpsCallable,
  storage,
} from '@util/firebase';
import {
  collection,
  CollectionReference,
  deleteDoc,
  deleteField,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { ref, uploadString } from 'firebase/storage';
import { CuratedListDocument, FlashDealDocument } from 'models/curated_list';
import { ProductDocument } from 'models/product';

const colRef = collection(
  db,
  'curated_lists'
) as CollectionReference<CuratedListDocument>;

export async function getCuratedLists() {
  const q = query(colRef, orderBy('index'));
  const snap = await getDocs(q);
  const data = snap.docs.map((doc) => doc.data() as CuratedListDocument);
  return data ?? [];
}

export async function getAllCuratedLists() {
  const q = query(colRef, orderBy('slug'));
  const snap = await getDocs(q);
  const data = snap.docs.map((doc) => doc.data() as CuratedListDocument);
  return data ?? [];
}

export async function addCuratedList(data: CuratedListDocument) {
  const ref = doc(colRef, data.id);
  await setDoc(ref, { ...data });
}

export async function updateCuratedList(data: CuratedListDocument) {
  // get list by slug
  const q = query(colRef, where('slug', '==', data.slug));
  const snap = await getDocs(q);
  const d = snap.docs[0];
  if (!d) return;
  // update doc
  const ref = doc(colRef, d.id);
  await updateDoc(ref, {
    ...data,
    // if index is undefined or zero , delete it
    index: data.index ? data.index : deleteField(),
  });
}

export async function disableCuratedList(id: string) {
  // remove index from list at id
  const q = query(colRef, where('id', '==', id));
  const snap = await getDocs(q);
  const d = snap.docs[0];
  if (!d) return;
  const ref = doc(colRef, d.id);
  await updateDoc(ref, {
    index: deleteField(),
  });
}

export async function deleteCuratedList(id: string) {
  const ref = doc(colRef, id);
  await deleteDoc(ref);
}

export async function getCuratedListBySlug(slug: string) {
  const q = query(colRef, where('slug', '==', slug));
  const snap = await getDocs(q);
  const data = snap.docs.map((doc) => doc.data()) as CuratedListDocument[];
  return data[0] ?? null;
}

export async function uploadCuratedImage(localUri: string, slug: string) {
  const path = `curated-lists/${slug}-${Date.now()}`;
  const storeImageRef = ref(storage, path);
  const res = await uploadString(storeImageRef, localUri, 'data_url');
  return res.metadata.fullPath;
}

export async function getPublicCuratedLists() {
  const q = query(colRef, where('is_public', '==', true));
  const snap = await getDocs(q);
  const data = snap.docs.map((doc) => doc.data()) as CuratedListDocument[];
  // filter out lists with no indexes
  // const filtered = data?.filter((d) => d.index !== undefined) ?? [];
  // return filtered;
  return data;
}

const flashColRef = collection(
  db,
  'flash_deal_requests'
) as CollectionReference<FlashDealDocument>;

export async function getFlashDealRequests() {
  const q = query(flashColRef, where('state', '==', 0), orderBy('created'));
  const snap = await getDocs(q);
  const data = snap.docs.map((doc) => doc.data());

  return data;
}

export async function getFlashDeals() {
  const ref = collection(
    db,
    'products'
  ) as CollectionReference<ProductDocument>;

  const q = query(
    ref,
    where('out_of_stock', '==', false),
    where('curated_lists', 'array-contains', 'flash-deals'),
    orderBy('end_time'),
    limit(20)
  );

  const snap = await getDocs(q);

  const results = snap.docs.map((doc) => doc.data());

  return results;
}

export async function setFlashDealState(args: {
  product_id: string;
  price: number;
  state: 1 | 2; // admin only
}) {
  await httpsCallable<typeof args, void>(
    functions,
    FirebaseCallable.addToFlashDeals
  )(args);
}
