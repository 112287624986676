'use client';

import PageSectionHeader from '@ui/PageSectionHeader';

interface HomeSectionProps {
  title?: string;
  href?: string;
  target?: string;
  linkText?: string;
  children: React.ReactNode;
  className?: string;
}

export default function HomeSection({
  title,
  href,
  target,
  linkText,
  children,
  className,
}: HomeSectionProps) {
  return (
    <div
      className={`my-[4rem] flex w-full flex-col sm:mx-auto sm:max-w-[150rem] ${className} overflow-visible`}
    >
      {title && (
        <div className="w-full">
          <PageSectionHeader
            title={title}
            href={href}
            target={target}
            linkText={linkText}
          />
        </div>
      )}

      <div className="h-full py-[2rem]">{children}</div>
    </div>
  );
}
