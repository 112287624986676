'use client';

import { Carousel } from '@c/index';
import { renderProductCards } from 'features/homepage/utils';
import { ProductDocument } from 'models/product';

type LiveAuctionsProps = {
  products: ProductDocument[];
  loading: boolean;
};

export default function LiveAuctions({ products, loading }: LiveAuctionsProps) {
  if (loading) {
    return (
      <div
        className={
          'flex h-full w-full items-center gap-x-2 overflow-x-scroll pb-16 pt-8 scrollbar-none'
        }
      >
        <div className="flex gap-x-2 pl-8 sm:hidden">
          {Array(3)
            .fill(Math.random())
            .map((_, i) => (
              <div
                key={i}
                className="h-[20rem] w-[20rem] animate-pulse rounded-2xl bg-brand-lightest-gray"
              />
            ))}
        </div>

        <div className="hidden gap-x-8 sm:flex">
          {Array(5)
            .fill(Math.random())
            .map((_, i) => (
              <div
                key={i}
                className="h-[30rem] w-[30rem] animate-pulse rounded-2xl bg-brand-lightest-gray"
              />
            ))}
        </div>
      </div>
    );
  } else
    return (
      <Carousel
        items={renderProductCards(products, {
          data_source: 'firebase',
          page: 'home',
          section: 'live-auctions',
        })}
      />
    );
}
