'use client';
/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import Carousel from '@ui/Carousel';
import ShopByBrand from '@ui/ShopByBrand';
import { logError } from '@util/logError';
import { BlogDocument } from '@util/types/firestore/blog';
import { useAuth } from 'context/AuthContext';
import {
  injectLogoScript,
  injectSoftwareAppScript,
} from 'features/schemaMarkups';
import { BannerDocument } from 'models/banner';
import { CuratedListDocument } from 'models/curated_list';
import { ProductDocument } from 'models/product';
import { Bike } from 'models/shared';
import { PublicUserDocument } from 'models/user';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { renderProductCards } from '../../utils';
import CantDecide from '../CantDecide';
import CuratedLists from '../CuratedLists';
import GetTheAppCTA from '../GetTheAppCTA';
import Highlights from '../Highlights';
import RecentlyViewed from '../RecentlyViewed';
import SeeWhatOurCustomersSay from '../SeeWhatOurCustomersSay';
import ShopBySport from '../ShopBySport';
import ThePaddock from '../ThePaddock';
import WhyMX from '../WhyMX';
import HomepageCats from './components/HomepageCats';
import HomepageHeader from './components/HomepageHeader';
import HomeSection from './components/HomeSection';
import LiveAuctions from '../LiveAuctions';
import { getLiveAuctions } from '@util/firestore/products';
import { useQuery } from '@tanstack/react-query';
import FlashDeals from '../FlashDeals';
import { getFlashDeals } from '@util/firestore/curated/curated.service';

type HomePageProps = {
  featuredProducts: ProductDocument[];
  featuredBikes: ProductDocument[];
  newArrivals: ProductDocument[];
  recentlySold: ProductDocument[];
  recentBikes: ProductDocument[];
  recentMTB: ProductDocument[];
  onSale: ProductDocument[];
  sellersToFollowData: (PublicUserDocument & { products: ProductDocument[] })[];
  lists: CuratedListDocument[];
  banners: BannerDocument[];
  paddockPosts: BlogDocument[];
};

export default function Home(props: HomePageProps) {
  const { userDoc } = useAuth();

  if (!props.sellersToFollowData) {
    const el = document.getElementById('__NEXT_DATA__') as HTMLScriptElement;
    logError(
      'HomePage props undefined ' + JSON.stringify(props),
      undefined,
      el.innerHTML
    );
  }
  const { newArrivals, onSale, lists, banners, paddockPosts } = props;

  const { data: liveAuctionProducts, isLoading: liveAuctionsLoading } =
    useQuery({
      queryKey: ['liveAuctions'],
      queryFn: getLiveAuctions,
      initialData: [],
    });

  const { data: flashDeals, isLoading: flashDealsLoading } = useQuery({
    queryKey: ['flash-deals'],
    queryFn: getFlashDeals,
    initialData: [],
  });

  return (
    <>
      <Script
        id="androidAppSchema"
        type="application/ld+json"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html:
            injectSoftwareAppScript({
              name: 'MX Locker',
              operatingSystem: 'ANDROID',
              applicationCategory: 'Application',
              ratingValue: '4.9',
              ratingCount: '1000',
              price: '0.00',
              priceCurrency: 'USD',
            }) ?? '',
        }}
      />
      <Script
        id="iosAppSchema"
        type="application/ld+json"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html:
            injectSoftwareAppScript({
              name: 'MX Locker',
              operatingSystem: 'IOS',
              applicationCategory: 'Application',
              ratingValue: '4.9',
              ratingCount: '1000',
              price: '0.00',
              priceCurrency: 'USD',
            }) ?? '',
        }}
      />
      <Script
        id="logoSchema"
        type="application/ld+json"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: injectLogoScript(
            'https://mxlocker.com/',
            'https://mxlocker.com/assets/mx-logo.png'
          ),
        }}
      />

      <HomepageCats />

      <HomepageHeader banners={banners} />

      <Highlights />

      {!!liveAuctionProducts.length && (
        <HomeSection title="Live Auctions" className="-mb-32">
          <LiveAuctions
            products={liveAuctionProducts}
            loading={liveAuctionsLoading}
          />
        </HomeSection>
      )}

      <div className="my-[2rem]"></div>

      <HomeSection title="New Arrivals" linkText="See All" href="/shop/recent">
        <Carousel
          items={renderProductCards(newArrivals, {
            data_source: 'firebase',
            page: 'home',
            section: 'new-arrivals',
          })}
        />
      </HomeSection>

      <HomeSection
        title="Shop By Sport"
        linkText="See All"
        href="/shop/all"
        className={`mx-auto  ${userDoc ? '' : '-mt-20 sm:-mt-32'}`}
      >
        <div className="w-full overflow-x-scroll pl-8 scrollbar-none sm:overflow-hidden sm:pl-0">
          <ShopBySport variant="main" />
        </div>
      </HomeSection>

      <HomeSection title="Shop By Brands" href={'/brands'} linkText="See All">
        <ShopByBrand />
      </HomeSection>

      {!!flashDeals?.length && (
        <FlashDeals
          title="Flash Deals ⚡"
          allProducts={flashDeals ?? []}
          isFetching={flashDealsLoading}
        />
      )}

      {userDoc && (
        <HomeSection title="Recently Viewed">
          <RecentlyViewed />
        </HomeSection>
      )}

      <CantDecide />

      <WhyMX />

      <HomeSection title="Price Drops" linkText="See All" href="/shop/sale">
        <Carousel
          items={renderProductCards(onSale, {
            data_source: 'firebase',
            page: 'home',
            section: 'on-sale',
          })}
        />
      </HomeSection>

      <HomeSection title="The Paddock" className="mx-auto  max-w-[150rem]">
        <ThePaddock posts={paddockPosts} />
      </HomeSection>

      <HomeSection title="Curated by MX Locker" className="mx-auto">
        <div className="overflow-x-auto scrollbar-none sm:flex sm:justify-center">
          <div className="flex w-max sm:w-auto">
            <CuratedLists lists={lists} />
          </div>
        </div>
      </HomeSection>

      <SeeWhatOurCustomersSay />

      <GetTheAppCTA />
    </>
  );
}
